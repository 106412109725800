<template>
  <responsive-form :form="form">
    <a-form-item label="歌曲ID">
      <a-input v-decorator="['id', { initialValue: 0 }]" :disabled="this.model != null" />
    </a-form-item>
    <a-form-item label="歌曲名">
      <a-input v-decorator="['name', {rules: [{required: true}], initialValue: ''}]" />
    </a-form-item>
    <a-form-item label="歌手名">
      <a-input v-decorator="['artistName', {rules: [{required: true}], initialValue: ''}]" />
    </a-form-item>
    <a-form-item label="排序名">
      <a-input v-decorator="['sortName', {rules: [{required: true}], initialValue: ''}]" />
    </a-form-item>
    <a-form-item label="分类">
      <a-select v-decorator="['genre', {rules: [{required: true}], initialValue: 'maimai'}]">
        <a-select-option v-for="genre in genreList" :value="genre" :key="genre">
          {{ genre }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="版权字">
      <a-input v-decorator="['rightsInfo', {rules: [], initialValue: ''}]" />
    </a-form-item>
    <a-form-item label="BPM">
      <a-input v-decorator="['bpm', {rules: [{required: true}], initialValue: 0}]" />
    </a-form-item>
    <a-form-item label="加入版本">
      <a-select v-decorator="['addVersion', {rules: [{required: true}], initialValue: 'SplashPLUS'}]">
        <a-select-option v-for="addVersion in addVersionList" :value="addVersion" :key="addVersion">
          {{ addVersion }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="开放时间">
      <a-input v-decorator="['releaseDate', {rules: [], initialValue: ''}]" />
    </a-form-item>
  </responsive-form>
</template>

<script>
import pick from 'lodash.pick'
import ResponsiveForm from '@/components/ResponsiveForm/ResponsiveForm'
import { Mai2MusicAddVersion, Mai2MusicGenre } from '@/model/mai2const'

// 表单字段
const fields = ['id', 'name', 'artistName', 'sortName', 'genre', 'rightsInfo', 'bpm', 'addVersion', 'releaseDate']

export default {
  components: { ResponsiveForm },
  props: {
    model: {
      type: Object,
      default: () => null
    },
    isTemplate: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      form: this.$form.createForm(this),
      genreList: Mai2MusicGenre,
      addVersionList: Mai2MusicAddVersion
    }
  },
  created () {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  }
}
</script>
